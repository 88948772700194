:root {
    --black-color: #000000;

    --bg-color: #141414;
    --bg-color-2: #161819;
    --bg-color-3: #222;

    --main-color: #FF0000;
    --main-color-2: #ED1D24;

    --text-color: #a9afc3;
    --text-muted: #6c757d;

    --track-color: #202324;
    --thumb-color: #454a4d;
    --thumb-hover-color: #596268;

    --fb-color: #3b5998;
    --google-color: #a61c00;
    --twitter-color: #1da1f2;

    --text-shadow: 1px 1px 2px rgba($color: #000000, $alpha: 0.8);
}